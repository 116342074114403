import { useEffect, useState } from 'react'

import { GetStationLivestreams, Livestream } from 'types/graphql'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import Card from 'src/components/Custom/Card/Card'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import {
  CREATE_STATION_USER,
  DEACTIVATE_USER,
  UPDATE_STATION_USER,
} from 'src/components/Custom/UsersEditor/queries'
import {
  UsersEditorData,
  UsersEditorProps,
} from 'src/components/Custom/UsersEditor/UsersEditor'
import UsersEditor from 'src/components/Custom/UsersEditor/UsersEditor'
import useUserStation from 'src/lib/hooks/useUserStation'
import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'
import { StationUrlProfiles } from 'src/lib/urls'

import About, { AboutData } from './About/About'
import Branding, { BrandingData } from './Branding/Branding'
import ContactDetails, {
  ContactDetailsData,
} from './ContactDetails/ContactDetails'
import DonationUrls, { DonationUrlsData } from './DonationUrls/DonationUrls'
import Links, { LinksData } from './Links/Links'
import Live, { LiveProps, Livestreams } from './Live/Live'
import {
  CREATE_LIVESTREAM,
  DELETE_LIVESTREAM,
  GET_STATION,
  GET_STATION_LIVESTREAMS,
  UPDATE_LIVESTREAM,
  UPDATE_STATION,
} from './queries'
import WebPortalLinks, {
  WebPortalLinksData,
  WebPortalLinksProps,
} from './WebPortal/WebPortalLinks'

const data = [
  {
    title: 'About page/card',
    hideSeparator: false,
    content: (props) => <About {...props} />,
  },
  {
    title: 'Branding',
    hideSeparator: false,
    content: (props) => <Branding {...props} />,
  },
  {
    title: 'Live',
    hideSeparator: false,
    content: (props: LiveProps) => <Live {...props} />,
  },
  {
    title: 'Contact Details',
    hideSeparator: false,
    content: (props) => <ContactDetails {...props} />,
  },
  {
    title: 'Links',
    hideSeparator: false,
    content: (props) => <Links {...props} />,
  },
  {
    title: 'Donation URLs',
    hideSeparator: false,
    content: (props) => <DonationUrls {...props} />,
  },
  {
    title: 'Web Portal Links',
    hideSeparator: false,
    content: (props: WebPortalLinksProps) => <WebPortalLinks {...props} />,
  },
  {
    title: 'Station Users',
    hideSeparator: false,
    content: (props: UsersEditorProps) => <UsersEditor {...props} />,
  },
]

interface LocalStationManagerState {
  aboutPage: AboutData
  branding: BrandingData
  contactDetails: ContactDetailsData
  donationUrls: DonationUrlsData
  links: LinksData
  liveData: Livestreams
  stationUsers: UsersEditorData
  webPortalLinks: WebPortalLinksData
}

type LocalStationManagerProps = {
  id?: string
}

const LocalStationManager = ({ id }: LocalStationManagerProps) => {
  const [state, setState] = useState<LocalStationManagerState | null>(null)
  const [showNewUser, setShowNewUser] = useState(false)

  const setUnsaved = useWarnUnsavedChanges()

  const { currentUser } = useAuth()
  const userStationId = useUserStation()
  const stationId = id ?? userStationId

  const { data: stationData, refetch } = useQuery(GET_STATION, {
    variables: { id: stationId },
  })

  const [updateStation, { loading: loadingUpdateStation }] = useMutation(
    UPDATE_STATION,
    {
      onCompleted: () => {
        toast.success('Update Successful')
      },
      onError: () => {
        toast.error('Error updating the station')
      },
    }
  )

  const [deactivateUser, { loading: loadingDeactivateUser }] = useMutation(
    DEACTIVATE_USER,
    {
      onCompleted: () => {
        toast.success('User deactivated successfully')
        refetch()
      },
      onError: () => {
        toast.error('Error deactivating user')
      },
    }
  )

  const [updateStationUser, { loading: loadingUpdateStationUser }] =
    useMutation(UPDATE_STATION_USER, {
      onCompleted: () => {
        toast.success('User updated successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Update failed: ${error?.message}`)
      },
    })

  const [createStationUser, { loading: loadingCreateStationUser }] =
    useMutation(CREATE_STATION_USER, {
      onCompleted: () => {
        toast.success('User created successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Create failed: ${error?.message}`)
      },
    })

  const handleStationUpdate = async (inputData) => {
    await updateStation({
      variables: {
        id: stationId,
        input: inputData,
      },
    })
  }

  const handleStationUserUpdate = async (userId, inputData) => {
    await updateStationUser({
      variables: {
        id: userId,
        input: inputData,
        pageName: 'localStationManager',
      },
    })
  }

  const handleCreateStationUser = async (inputData) => {
    await createStationUser({
      variables: {
        input: { stationId: stationId, ...inputData },
        pageName: 'localStationManager',
      },
    })
  }

  const handleDeactivateUser = async (id: string) => {
    await deactivateUser({ variables: { id } })
  }

  const { data: livestreamData, refetch: refetchLivestreamData } =
    useQuery<GetStationLivestreams>(GET_STATION_LIVESTREAMS, {
      variables: { stationId: stationId },
    })

  const [updateLivestream, { loading: loadingUpdateLivestream }] = useMutation(
    UPDATE_LIVESTREAM,
    { onCompleted: () => refetchLivestreamData() }
  )

  const [deleteLivestream] = useMutation(DELETE_LIVESTREAM, {
    onCompleted: () => refetchLivestreamData(),
  })

  const [createLivestream, { loading: loadingCreateLivestream }] = useMutation(
    CREATE_LIVESTREAM,
    { onCompleted: () => refetchLivestreamData() }
  )

  const handleLivestreamUpdate = async (id, inputData, showAlerts = true) => {
    if (showAlerts) {
      await toast.promise(
        updateLivestream({
          variables: {
            id: id,
            input: inputData,
          },
        }),
        {
          loading: 'Updating livestream...',
          success: 'Livestream updated successfully',
          error: 'Error uploading livestream',
        }
      )
    } else {
      await updateLivestream({
        variables: {
          id: id,
          input: inputData,
        },
      })
    }
  }

  const handleLivestreamDelete = async (id) => {
    await toast.promise(deleteLivestream({ variables: { id: id } }), {
      loading: 'Deleting livestream...',
      success: 'Livestream deleted successfully',
      error: 'Error deleting livestream',
    })
  }

  const handleLivestreamCreation = async (input) => {
    await toast.promise(createLivestream({ variables: { input: input } }), {
      loading: 'Creating livestream...',
      success: 'Livestream created successfully',
      error: 'Error creating livestream',
    })
  }

  useEffect(() => {
    if (
      stationData &&
      stationData.station &&
      livestreamData &&
      livestreamData.stationLivestreams
    ) {
      const findUrl = (profile: string): string | undefined => {
        return stationData.station.urls.find(
          (url: { profile: string; url: string }) => url.profile === profile
        )?.url
      }

      const newState = {
        aboutPage: {
          name: stationData.station.name,
          about: stationData.station.about,
          images: stationData.station.images,
        },
        branding: {
          colors: [
            {
              label: 'Primary',
              labelTooltip:
                'The primary color is used for buttons and menu/thumbnail selection indicators.',
              color: stationData.station.colorPrimary
                ? stationData.station.colorPrimary
                : '#FFFFFF',
              id: 'primary',
            },
            {
              label: 'Secondary',
              labelTooltip:
                'The secondary color is used for menu backgrounds and must contrast against the primary color.',
              color: stationData.station.colorSecondary
                ? stationData.station.colorSecondary
                : '#FFFFFF',
              id: 'secondary',
            },
            {
              label: 'Background',
              labelTooltip:
                'The background color is used for the app background and can be a solid shade or gradient.',
              color: stationData.station.colorBackground
                ? stationData.station.colorBackground
                : '#FFFFFF',
              id: 'background',
            },
          ],
          images: stationData.station.images,
        },
        liveData: {
          channels: livestreamData.stationLivestreams.map(
            (item: Livestream, index: number) => ({
              id: item.id,
              label: `Channel ${index + 1}`,
              name: item.name,
              logo: item.logo,
              stationDescription: item.description,
              shortStationDescription: item.shortDescription,
              pbsTvssFeedCid: item.pbsTvssFeedCid,
              source: {
                embedCode: item.embedCode,
                sourceUrl: item.sourceUrl,
                pbsStationManager: item.pbsStationManagerLivestreamFeedCid,
                livestreamCom: {
                  livestreamComAccountId: item.livestreamComAccountId,
                  livestreamComEventId: item.livestreamComEventId,
                },
              },
              weight: item.weight,
            })
          ),
        },
        contactDetails: {
          email: stationData.station.email,
          telephone: stationData.station.phone,
          fax: stationData.station.fax,
          addressStreet: stationData.station.addressStreet,
          addressCity: stationData.station.addressCity,
          addressState: stationData.station.addressState,
          addressZip: stationData.station.addressZip,
          [StationUrlProfiles.FACEBOOK]: findUrl(StationUrlProfiles.FACEBOOK),
          [StationUrlProfiles.TWITTER]: findUrl(StationUrlProfiles.TWITTER),
          [StationUrlProfiles.INSTAGRAM]: findUrl(StationUrlProfiles.INSTAGRAM),
          [StationUrlProfiles.TIKTOK]: findUrl(StationUrlProfiles.TIKTOK),
          [StationUrlProfiles.YOUTUBE]: findUrl(StationUrlProfiles.YOUTUBE),
          [StationUrlProfiles.MASTODON]: findUrl(StationUrlProfiles.MASTODON),
          urls: stationData.station.urls,
        },
        links: {
          [StationUrlProfiles.CONTACT]: findUrl(StationUrlProfiles.CONTACT),
          [StationUrlProfiles.DONATE]: findUrl(StationUrlProfiles.DONATE),
          [StationUrlProfiles.HELP]: findUrl(StationUrlProfiles.HELP),
          [StationUrlProfiles.PASSPORT]: findUrl(StationUrlProfiles.PASSPORT),
          [StationUrlProfiles.PRIVACY_POLICY]: findUrl(
            StationUrlProfiles.PRIVACY_POLICY
          ),
          [StationUrlProfiles.TERMS_OF_USE]: findUrl(
            StationUrlProfiles.TERMS_OF_USE
          ),
          urls: stationData.station.urls,
        },
        donationUrls: {
          [StationUrlProfiles.DONATE_URL_ANDROID]: findUrl(
            StationUrlProfiles.DONATE_URL_ANDROID
          ),
          [StationUrlProfiles.DONATE_URL_FIRETV]: findUrl(
            StationUrlProfiles.DONATE_URL_FIRETV
          ),
          [StationUrlProfiles.DONATE_URL_GOOGLETV]: findUrl(
            StationUrlProfiles.DONATE_URL_GOOGLETV
          ),
          [StationUrlProfiles.DONATE_URL_IOS]: findUrl(
            StationUrlProfiles.DONATE_URL_IOS
          ),
          [StationUrlProfiles.DONATE_URL_LGTV]: findUrl(
            StationUrlProfiles.DONATE_URL_LGTV
          ),
          [StationUrlProfiles.DONATE_URL_ROKU]: findUrl(
            StationUrlProfiles.DONATE_URL_ROKU
          ),
          [StationUrlProfiles.DONATE_URL_SAMSUNGTV]: findUrl(
            StationUrlProfiles.DONATE_URL_SAMSUNGTV
          ),
          [StationUrlProfiles.DONATE_URL_TVOS]: findUrl(
            StationUrlProfiles.DONATE_URL_TVOS
          ),
          [StationUrlProfiles.DONATE_URL_WEB]: findUrl(
            StationUrlProfiles.DONATE_URL_WEB
          ),
          urls: stationData.station.urls,
        },
        webPortalLinks: {
          links: stationData.station.webPortalLinks || [],
        },
        stationUsers: {
          users: stationData.station.users.map((user) => ({
            id: user.id,
            name: user.name,
            roles: user.roles,
            email: user.email,
            stationId: user.stationId,
            active: user.active,
          })),
        },
      }
      setUnsaved(true)
      setState(newState)
    }
  }, [livestreamData, showNewUser, stationData, setUnsaved])

  const stateProperties = [
    { data: state?.aboutPage, key: 'aboutPage', type: 'AboutData' },
    { data: state?.branding, key: 'branding', type: 'BrandingData' },
    {
      data: state?.liveData,
      key: 'live',
      type: 'LiveData',
    },
    {
      data: state?.contactDetails,
      key: 'contactDetails',
      type: 'ContactDetailsData',
    },
    { data: state?.links, key: 'links', type: 'LinksData' },
    {
      data: state?.donationUrls,
      key: 'donationUrls',
      type: 'DonationUrlsData',
    },
    {
      data: state?.webPortalLinks,
      key: 'webPortalLinks',
      type: 'WebPortalLinksData',
    },
    {
      data: state?.stationUsers,
      key: 'stationUsers',
      type: 'StationUsersData',
    },
  ]

  const stateToProp = (index: number) => {
    const { data, key } = stateProperties[index]
    return {
      data,
      onChange: (dataToUpdate) => {
        setState((oldValue) => ({
          ...oldValue,
          [key]: dataToUpdate,
        }))
      },
    }
  }

  const isStateNull = () => {
    return state !== null && stationData !== undefined
  }

  return (
    <div className="w-full bg-gray-100 p-4">
      <Metadata title="Station Setings" />
      <HeadingLevel level={1} className="mb-4">
        Station Settings
      </HeadingLevel>
      {isStateNull() && id && (
        <HeadingLevel level={2}>{state.aboutPage.name}</HeadingLevel>
      )}

      <div className="mt-5 space-y-6">
        {isStateNull() &&
          data.map((d, index) => (
            <Card key={d.title} {...d}>
              {d.content({
                ...stateToProp(index),
                updateStation: handleStationUpdate,
                loadingUpdateStation: loadingUpdateStation,
                ...(d.title === 'Live' && {
                  updateLivestream: handleLivestreamUpdate,
                  createLivestream: handleLivestreamCreation,
                  deleteLivestream: handleLivestreamDelete,
                  stationId: stationId,
                  loadingCreateLivestream: loadingCreateLivestream,
                  loadingUpdateLivestream: loadingUpdateLivestream,
                }),
                ...(d.title === 'Station Users' && {
                  updateUserData: handleStationUserUpdate,
                  showNewUser: showNewUser,
                  setShowNewUser: setShowNewUser,
                  currentUserId: currentUser.id,
                  createUser: handleCreateStationUser,
                  deactivateUser: handleDeactivateUser,
                  roleType: 'STATION',
                  loading:
                    loadingCreateLivestream ||
                    loadingCreateStationUser ||
                    loadingDeactivateUser ||
                    loadingUpdateLivestream ||
                    loadingUpdateStation ||
                    loadingUpdateStationUser,
                }),
              })}
            </Card>
          ))}
      </div>
    </div>
  )
}

export default LocalStationManager
