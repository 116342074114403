export const GET_SHOWS_WITH_MISSING_METADATA = gql`
  query GetShowsWithMissingMetadata(
    $search: String
    $start: Date
    $end: Date
    $filters: FilterShowInput
  ) {
    missingMetadataShows(
      search: $search
      start: $start
      end: $end
      filters: $filters
    ) {
      createdAt
      earliestFullLengthContentAvailableAt
      id
      images {
        image
        profile
      }
      title
    }
  }
`
