import * as Sentry from '@sentry/react'

// Initilize Sentry only once. Sentry uses a singleton pattern internally so
// no export is needed.
// @see https://docs.sentry.io/platforms/javascript/guides/react/#configure
Sentry.init({
  beforeSend(event) {
    // Remove PII from user.
    if (event.user) {
      delete event.user.email
    }
    return event
  },
  dsn: process.env.SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.25,
})
Sentry.setTag('app_side', 'web')

export default Sentry
