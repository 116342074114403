import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { GetShowsWithMissingMetadata, ImageWithProfile } from 'types/graphql'

import { DateField, FormProvider, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Custom/Button/Button'
import Checkbox from 'src/components/Custom/Checkbox/Checkbox'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import TextInputComponent from 'src/components/Custom/TextInput/TextInput'
import { formatDateValue } from 'src/lib/formatters'
import { getImage } from 'src/lib/images'

import { GET_SHOWS_WITH_MISSING_METADATA } from '../queries'

import { missingMetadataHeader } from './constants'

/**
 * DateRangeFields component.
 */
const DateRangeFields = ({
  startDate,
  endDate,
  onChange,
}): React.JSX.Element => {
  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <HeadingLevel level={3} levelStyle={4} className="mb-2">
          Start Date
        </HeadingLevel>
        <DateField
          onChange={(e) => onChange('start', e.target.value)}
          name="startDate"
          value={startDate ? formatDateValue(startDate) : ''}
          className="date-picker-input w-full rounded-md border border-gray-300 py-2 pl-8"
        />
      </div>

      <div className="flex-1">
        <HeadingLevel level={3} levelStyle={4} className="mb-2">
          End Date
        </HeadingLevel>
        <DateField
          onChange={(e) => onChange('end', e.target.value)}
          name="endDate"
          value={endDate ? formatDateValue(endDate) : ''}
          className="date-picker-input w-full rounded-md border border-gray-300 py-2 pl-8"
        />
      </div>
    </div>
  )
}

const MissingMetadatListing = () => {
  const [dates, setDates] = useState<{ start?: Date; end?: Date }>({
    start: null,
    end: new Date(), // Default end date = today.
  })
  const [shows, setShows] = useState([])
  const [search, setSearch] = useState<string>()
  const [filters, setFilters] = useState({
    limit: 25,
    skip: 0,
    sortBy: {
      value: 'createdAt',
      sort: 'desc',
    },
    hasAvailableFullLengthContent: true,
  })

  const methods = useForm()
  const { data } = useQuery<GetShowsWithMissingMetadata>(
    GET_SHOWS_WITH_MISSING_METADATA,
    {
      variables: {
        search,
        start: dates?.start
          ? dayjs(dates.start).format('YYYY-MM-DD')
          : undefined,
        end: dates?.end ? dayjs(dates.end).format('YYYY-MM-DD') : undefined,
        filters: {
          ...filters,
          sortBy: {
            [filters.sortBy.value]: filters.sortBy.sort,
          },
        },
      },
    }
  )

  useEffect(() => {
    if (data && data.missingMetadataShows) {
      setShows(
        data.missingMetadataShows.map((show) => ({
          ...show,
          image: getImage(show.images as Array<ImageWithProfile>).image,
          emptyFields: 'GenreVibes',
        }))
      )
    }
  }, [data])

  const onClickEditItem = (itemData) =>
    navigate(routes.editGlobalShow({ id: itemData.id }))

  const handlePageChange = (toPage: number) => {
    const currentPage = filters.skip / filters.limit
    if (
      toPage > -1 &&
      (data.missingMetadataShows.length === filters.limit ||
        toPage < currentPage)
    ) {
      setFilters((old) => ({ ...old, skip: toPage * old.limit }))
    }
  }

  const actionsList = (itemData) => {
    return (
      <div className="flex flex-col p-2">
        <Button
          title="Edit"
          onClick={() => onClickEditItem(itemData)}
          backgroundColor="bg-blue-600"
          customButtonClass="mb-2"
        />
      </div>
    )
  }

  return (
    <div className="w-full p-4">
      <Metadata title="Shows Missing Metadata" />
      <HeadingLevel level={1}>Shows Missing Metadata</HeadingLevel>
      <FormProvider {...methods}>
        <div className="mt-5">
          <div>
            PBS genres are not editable. However, admins may override the Genre
            on each Show (which feeds down to child Videos).
          </div>
          <div className="mt-8 w-full">
            <span className="block pb-2">Search by Show Title</span>
            <TextInputComponent
              type="text"
              name="contentLibrarySearch"
              placeholder="Show title"
              width="full"
              value={search}
              onChange={(input) => {
                setFilters((old) => ({
                  ...old,
                  skip: 0,
                }))
                setSearch(input?.length ? input : undefined)
              }}
            />
          </div>
          <div className="flex items-center pb-4 pt-6">
            <div>
              <DateRangeFields
                startDate={dates.start}
                endDate={dates.end}
                onChange={(key: string, value: string) => {
                  setDates((old) => {
                    return {
                      ...old,
                      [key]: value,
                    }
                  })
                }}
              />
              <Checkbox
                id="exclude-shows-without-full-length-content"
                title="Exclude shows without full-length content"
                onValueChange={(updatedValue) =>
                  setFilters({
                    ...filters,
                    hasAvailableFullLengthContent: updatedValue
                      ? true
                      : undefined,
                  })
                }
                defaultValue={filters.hasAvailableFullLengthContent}
                checkboxContainerClass="pt-4"
              />
            </div>
          </div>

          <ContentListingTable
            contentList={shows}
            tableHeading={missingMetadataHeader}
            handleSort={({ sortBy, toggle }) =>
              setFilters((old) => ({
                ...old,
                sortBy: {
                  value: sortBy,
                  sort: toggle.toLowerCase(),
                },
              }))
            }
            actionsList={actionsList}
            paginationConfig={{
              size: filters.limit,
              page: filters.skip / filters.limit,
            }}
            onPageChange={handlePageChange}
          />
        </div>
      </FormProvider>
    </div>
  )
}

export default MissingMetadatListing
