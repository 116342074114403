import { useState } from 'react'

import {
  FieldError,
  Form,
  Label,
  Submit,
  TextField,
  useForm,
} from '@redwoodjs/forms'

import Button from '../Button/Button'
import Loader from '../Loader/Loader'

export type WebLink = {
  title: string
  url: string
}

interface UpdateableWebLinkProps {
  editing?: boolean
  loadingData: boolean
  onDelete?: () => Promise<void>
  onSave?: (value: WebLink) => Promise<void>
  title?: string
  url?: string
}

const UpdateableWebLink = ({
  editing = false,
  loadingData,
  onDelete,
  onSave,
  title,
  url,
}: UpdateableWebLinkProps) => {
  const [editable, setEditable] = useState(editing)
  const formMethods = useForm()

  const handleCancel = () => {
    setEditable(false)
    formMethods.reset()

    if (editing) onDelete()
  }

  const onFormSubmit = (value: WebLink) => {
    onSave(value)
    setEditable(false)
  }

  return (
    <Form formMethods={formMethods} onSubmit={onFormSubmit} className="w-full">
      {editable ? (
        <div className="flex gap-4">
          <div className="flex w-full flex-grow flex-col">
            <Label
              name="Link Title"
              className="block text-sm font-medium text-gray-700"
            />
            <TextField
              name="title"
              defaultValue={title}
              validation={{
                required: 'Please enter a valid title',
              }}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <FieldError name="title" className="text-red-700" />
          </div>
          <div className="flex w-full flex-grow flex-col">
            <Label
              name="URL"
              className="block text-sm font-medium text-gray-700"
            />
            <TextField
              name="url"
              defaultValue={url}
              validation={{
                required: 'Please enter a valid URL',
                validate: {
                  url: (value: string) => {
                    const urlPattern = /^http/
                    if (!value || !urlPattern.test(value)) {
                      return 'Please enter a valid URL'
                    }
                    return true
                  },
                },
              }}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <FieldError name="url" className="text-red-700" />
          </div>

          <div className="justify-top flex flex-col">
            <Submit disabled={loadingData} className="border-0 text-indigo-700">
              {loadingData ? <Loader /> : 'Save'}
            </Submit>

            <Button
              title="Cancel"
              customButtonClass="border-0 text-indigo-700"
              onClick={handleCancel}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <p className="flex-1">{url || <em>Not set</em>}</p>

          <Button
            title="Update"
            customButtonClass="border-0 text-indigo-700"
            onClick={() => setEditable(!editable)}
          />
          <Button
            title="Delete"
            customButtonClass="border-0 text-red-700"
            onClick={() => onDelete()}
          />
        </div>
      )}
    </Form>
  )
}

export default UpdateableWebLink
