import { useState } from 'react'

import { GetNewShows } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { ContentItemType } from 'src/common/types'
import Button from 'src/components/Custom/Button/Button'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import Dropdown from 'src/components/Custom/Dropdown/Dropdown'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import PrimaryModal from 'src/components/Custom/Modal/PrimaryModal'
import { UserRole } from 'src/lib/enums'
import { getImage } from 'src/lib/images'

import { newShowsByDateOptions, newShowsHeader } from './constants'

export const GET_NEW_SHOWS = gql`
  query GetNewShows($byDate: DateTime!, $filters: FilterNewShowInput) {
    newShows(byDate: $byDate, filters: $filters) {
      id
      title
      images {
        image
        profile
      }
      createdAt
      updatedAt
    }
  }
`

interface NewShowsListingState {
  showEditModal: boolean
  selectedItemId?: string
}

interface NewShowsFilters {
  limit?: number
  skip?: number
}

const NewShowsListing = () => {
  const { currentUser } = useAuth()
  const isGlobalUser =
    currentUser?.roles?.includes(UserRole.GLOBAL_PROGRAMMER) ||
    currentUser?.roles?.includes(UserRole.ADMIN)

  const [content, setContent] = useState<ContentItemType[]>([])
  const [state, setState] = useState<NewShowsListingState>({
    showEditModal: false,
    selectedItemId: null,
  })

  const defaultFiltersState = {
    limit: 25,
    skip: 0,
  }
  const [filters, setFilters] = useState<NewShowsFilters>(defaultFiltersState)

  const currentZeroDate = () => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  // Set default byDate option ("One week").
  const defaultDateOption = newShowsByDateOptions[0]
  const getDefaultDate = (): Date => {
    const currentDate = currentZeroDate()
    currentDate.setDate(
      currentDate.getDate() - parseInt(defaultDateOption.value)
    )

    return currentDate
  }
  const [byDate, setByDate] = useState<Date>(getDefaultDate())

  const onClickEditItem = (itemData) => {
    setState({ showEditModal: true, selectedItemId: itemData.id })
  }

  const { data, refetch } = useQuery<GetNewShows>(GET_NEW_SHOWS, {
    variables: {
      byDate,
      filters: { ...filters },
    },
    onCompleted: ({ newShows }) => {
      if (newShows) {
        const elements = newShows.map((show) => {
          const poster = getImage(show.images)
          return {
            id: show.id,
            title: show.title,
            image: poster.image,
            createdAt: new Date(show.createdAt).toDateString(),
          }
        })
        setContent(elements)
      }
    },
  })

  const actionsList = (itemData) => {
    return (
      <div className="flex flex-col p-2">
        <Button
          title="Edit"
          onClick={() => onClickEditItem(itemData)}
          backgroundColor="bg-blue-600"
          customButtonClass="mb-2"
        />
      </div>
    )
  }

  const handleDateChange = (option: { label: string; value: string }) => {
    const currentDate = currentZeroDate()
    currentDate.setDate(currentDate.getDate() - parseInt(option.value))
    setByDate(currentDate)
    // Reset filters.
    setFilters(defaultFiltersState)
    refetch()
  }

  const handlePageChange = (toPage: number) => {
    const currentPage = filters.skip / filters.limit
    if (
      toPage > -1 &&
      (data.newShows.length === filters.limit || toPage < currentPage)
    ) {
      setFilters((old) => ({ ...old, skip: toPage * old.limit }))
    }
  }

  return (
    <div className="w-full p-4">
      <Metadata title="New Shows" />
      <HeadingLevel level={1}>New Shows</HeadingLevel>
      <div className="mt-5">
        <div>
          These shows just came in from Media Manager and need a metadata pass
        </div>
        <div className="pb-4 pt-6">
          <span className="pl-5 pr-2">How new?</span>
          <Dropdown
            options={newShowsByDateOptions}
            buttonText={defaultDateOption.label}
            onOptionsChange={handleDateChange}
          />
        </div>
        <ContentListingTable
          contentList={content}
          tableHeading={newShowsHeader}
          handleSort={() => {}}
          actionsList={actionsList}
          emptyMessage="We did it! There are no more new shows that need attention."
          paginationConfig={{
            size: filters.limit,
            page: filters.skip / filters.limit,
          }}
          onPageChange={handlePageChange}
        />
        <PrimaryModal
          modalText={
            'If you edit this show, it will  no longer appear in the New Shows queue'
          }
          buttonList={[
            {
              label: 'Continue',
              id: 'continue',
              type: 'primary',
              onClick: () => {
                navigate(
                  isGlobalUser
                    ? routes.editGlobalShow({ id: state.selectedItemId ?? '' })
                    : routes.editShow({ id: state.selectedItemId ?? '' })
                )
              },
            },
            {
              label: 'Cancel',
              id: 'cancel',
              type: 'secondary',
              onClick: () => {
                setState({ showEditModal: false })
              },
            },
          ]}
          showModal={state.showEditModal}
          setShowModal={(showEditModal) => setState({ showEditModal })}
        />
      </div>
    </div>
  )
}

export default NewShowsListing
