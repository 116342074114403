import { useState } from 'react'

import Button from 'src/components/Custom/Button/Button'
import CardSection from 'src/components/Custom/Card/CardSection'
import UpdateableWebLink, {
  WebLink,
} from 'src/components/Custom/UpdateableWebLink/UpdatableWebLink'

import { StationInputType, WeightedWebLink } from '../../../common/types'

export interface WebPortalLinksData {
  links: WeightedWebLink[]
}

export interface WebPortalLinksProps {
  data: WebPortalLinksData
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
}

const WebPortalLinks = ({
  data,
  updateStation,
  loadingUpdateStation,
}: WebPortalLinksProps) => {
  const { links = [] } = data
  const [showNewWebPortalLink, setShowNewWebPortalLink] = useState(false)

  const updateLinks = (value: WebLink, index: number = -1) => {
    const updatedLinks = [...links]
    const { title, url } = value

    if (index !== -1) {
      updatedLinks[index] = { title, url, weight: index }
    } else {
      updatedLinks.push({ title, url, weight: links.length })
    }

    return updatedLinks
  }

  const createWebLink = async (data: WebLink) => {
    const updatedLinks = updateLinks(data)
    await updateStation({ webPortalLinks: updatedLinks }).then(() =>
      setShowNewWebPortalLink(false)
    )
  }

  const deleteWebLink = async (index: number) => {
    if (index !== -1) {
      let updatedLinks = [...links]
      if (updatedLinks.length === 1) {
        updatedLinks = []
      } else {
        updatedLinks.splice(index, 1)
      }

      // Adjust weights of other web links after deleting.
      const weightedLinks = []
      if (updatedLinks.length) {
        updatedLinks.forEach((link, index) => {
          const weightedLink = { ...link, weight: index }
          weightedLinks.push(weightedLink)
        })
      }
      await updateStation({ webPortalLinks: weightedLinks })
    } else {
      setShowNewWebPortalLink(false)
    }
  }

  return (
    <div className="w-full space-y-2">
      <div className="my-4">
        {!!links.length &&
          links.map((item, index) => {
            return (
              <div className="flex w-full" key={`link-${index}`}>
                <CardSection title={item.title || 'no title'}>
                  <UpdateableWebLink
                    {...item}
                    onSave={(value) =>
                      updateStation({
                        webPortalLinks: updateLinks(value, index),
                      })
                    }
                    onDelete={() => deleteWebLink(index)}
                    loadingData={loadingUpdateStation}
                  />
                </CardSection>
              </div>
            )
          })}
        {showNewWebPortalLink && (
          <div className="flex w-full">
            <UpdateableWebLink
              onSave={(value) => createWebLink(value)}
              onDelete={() => deleteWebLink(-1)}
              editing={true}
              loadingData={loadingUpdateStation}
            />
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row justify-end">
        {links.length < 4 && (
          <Button
            title="Add link"
            onClick={() => setShowNewWebPortalLink(true)}
          />
        )}
      </div>
    </div>
  )
}

export default WebPortalLinks
